<template>
  <div class="box">
    <div class="header">
      <p class="back" @click="$router.go(-1)">
        < 返回</p>
          <p class="info"><span>反馈记录</span></p>
          <div class="title">
            <p>计划名：{{data.advName}}</p>
            <p>推广时间：{{data.launchStartDay + ' - ' + (data.launchEndDay == '0' ? '不限' : data.launchEndDay)}}</p>
          </div>
    </div>
    <!-- 反馈记录列表 -->
    <el-table :data="adverData" @selection-change="handleSelectionChange" v-loading="loading">
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column prop="id" label="反馈ID" align="center" width="120"></el-table-column>
      <el-table-column prop="nickName" label="昵称" align="center"></el-table-column>
      <el-table-column prop="contact" label="联系方式" align="center"></el-table-column>
<!--      <el-table-column prop="remarks" label="留言信息" align="center"></el-table-column> -->
      <el-table-column prop="advId" label="广告反馈ID" align="center"></el-table-column>
      <el-table-column prop="addTime" label="反馈时间" align="center" width="150"></el-table-column>
    </el-table>
    <div class="block">
      <!-- 导出表格按钮 -->
      <el-button type="primary" @click="exportReport">批量导出Excel</el-button>
      <!-- 反馈记录列表分页 -->
      <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currpage"
        :page-sizes="[10, 50, 100]" :page-size="pagesize" background layout="total,prev, pager, next,sizes,jumper"
        :total="CountNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    feedBackList,
    advInfo
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        advId: JSON.parse(this.$route.params.advId), //广告id
        adverData: [], // 反馈列表
        data: {}, //广告信息
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        multipleSelection: [], // 选择的列表项
      }
    },
    mounted() {
      document.getElementById("mainRight").style.display = 'none'
      this.feedBackList({}, 1, this.pagesize) // 获取广告反馈列表
      this.advInfo() //获取广告信息
    },
    methods: {
      feedBackList(serchJson, currpage, pageSize) { // 广告反馈列表
        this.loading = true
        const data = serchJson
        data["page"] = currpage //	当前页数
        data["limit"] = pageSize // 每页条数
        data["advId"] = this.advId // 广告反馈id
        feedBackList(data).then(res => {
          this.adverData = res.data.List // 反馈列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.currpage = currpage //分页显示当前页
          this.pagesize = pageSize
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      advInfo() { // 广告信息
        const data = {}
        data["advId"] = this.advId // 广告id
        advInfo(data).then(res => {
          this.data = res.data // 广告信息
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(num) { // 分页
        this.feedBackList({}, num, this.pagesize)
      },
      handleSizeChange(num) { //每页显示条数
        this.feedBackList({}, 1, num)
      },
      handleSelectionChange(val) { //表格复选框被选中触发
        this.multipleSelection = val;
      },
      exportReport() { //导出数据
        // 列标题
        let str = '<tr><td>反馈ID</td><td>昵称</td><td>电话</td><td>留言信息</td><td>广告反馈ID</td><td>反馈时间</td></tr>';
        // 循环遍历，每行加入tr标签，每个单元格加td标签
        for (let i = 0; i < this.multipleSelection.length; i++) {
          str += '<tr>';
          for (const key in this.multipleSelection[i]) {
            // 增加\t为了不让表格显示科学计数法或者其他格式
            str += `<td>${ this.multipleSelection[i][key] + '\t'}</td>`;
          }
          str += '</tr>';
        }
        // Worksheet名
        const worksheet = 'Sheet1'
        const uri = 'data:application/vnd.ms-excel;base64,';
        // 下载的表格模板数据
        const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><meta charset="utf-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;
        // 下载模板
        window.location.href = uri + this.base64(template);
      },
      // 输出base64编码
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box {
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    margin-bottom: 20px;

    .header {
      border-bottom: 1px solid #cccccc;
      padding-top: 10px;
      position: relative;

      .back {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        font-size: 18px;
        text-align: left;
        color: gray;
        margin-left: 10px;
        cursor: pointer;
      }

      .info {
        margin: 0;
        padding: 0;
        height: 32px;
        font-size: 18px;
        text-align: left;
        color: #1890FF;
        margin-left: 10px;

        span {
          display: inline-block;
          line-height: 30px;
          border-bottom: 2px solid #1890FF;
        }
      }

      .title {
        position: absolute;
        top: 0;
        left: 45%;

        p:nth-child(1) {
          margin-top: 10px;
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .block {
      display: grid;
      grid-template-columns: 130px 80%;
      grid-template-rows: 60px;
      padding-left: 10px;
      align-items: center;
    }
  }
</style>
